<template>
	<div class="search-page">
        <vanTitle title="课程搜索"></vanTitle>
        <section class="search-ipt">
            <form action="/">
                <van-search
                    v-model="state.keyword"
                    show-action
                    placeholder="请输入搜索关键词"
                    @search="onSearch"
                    @cancel="onCancel"
                />
            </form>
        </section>
        <section class="history-list" v-if="state.searchHistory != '' && state.courseList == '' && !state.is_search" >
            <div class="history-search">
                <div>搜索历史</div>
                <div @click="deleteHistory" class="icon-delete"></div>
            </div>
            <div class="histort-item">
                <div v-for="(item, index) in state.searchHistory" @click="selectTag(item)" :key="index" class="item">{{ item }}</div>
            </div>
        </section>
        <section class="history-list" v-if="state.hotwordsList != '' && state.courseList == '' && !state.is_search" >
            <div class="history-search">
                <div>热门搜索</div>
            </div>
            <div class="histort-item">
                <div v-for="(item, index) in state.hotwordsList" @click="hotSearch(item)" :key="index" class="item">{{ item }}</div>
            </div>
        </section>
        <div class="div" v-if="state.courseList == ''"></div>
		<KechList :courseList="state.courseList" :loading="state.loading" @onLoad="onLoad" :finished="state.finished" type="search" :show="state.show" text="暂无搜索结果哦～"></KechList>
	</div>
</template>

<script>
import { ref, reactive, onMounted, watchEffect, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getStore, dedupe, sensorTrack } from "../../utils/util";
import { useStore} from "vuex";
import KechList from "../../components/kechList/index.vue";
export default {
  name: "search",
  components: {
	KechList
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const $route = useRoute();
    const store = useStore();
    const filter = ref(null);
    const state = reactive({
		courseList: [],//课程列表
        hotwordsList: '',//热词搜索数据
		loading: false,//loading
		finished: false,//分页加载完成
		total: 0,//数据总数
		page: 1,//分页
        keyword:'',//搜索关键词
        searchHistory: [],
        shop_id: 'n',
        show: false,//课程列表
        is_search: false,
    });

    onMounted(() => {
        //全局shop_id
		if(store.state.globalData.shop_id){
			state.shop_id = store.state.globalData.shop_id;
		}
        
        // 历史搜索
        let searchHistory = getStore("searchHistory");
        if (searchHistory) {
            store.commit("search/setHistory", searchHistory);
            state.searchHistory = searchHistory;
        }

        getHotwords();//获取热门搜索词
    })

    // watchEffect(() => {
    //     state.searchHistory = store.state.search.searchHistory;
    // })

    // 搜索
    const onSearch = (val) => {
        let keyword = val.replace(/^\s+|\s+$/g, ""); //去除两头空格
        if (keyword) {
            selectTag(keyword);
        }
    }

    // 热门搜索
    const hotSearch = (val) => {
        let keyword = val.replace(/^\s+|\s+$/g, ""); //去除两头空格
        if (keyword) {
            state.keyword = keyword;
            onCancel();//初始化数据
            getdata();
        }
    }

    // 取消
    const onCancel = () => {
        state.courseList = [];
        state.page = 1;
        state.total = 0;
    };

    const selectTag = (keyword) => {
        state.keyword = keyword;
        store.commit("search/addHistory", keyword);
        store.commit("search/setHistory", dedupe(state.searchHistory));

        onCancel();//初始化数据
        getdata();
    };

    // 删除搜索历史
    const deleteHistory = () => {
        state.searchHistory = [];
        store.commit("search/setHistory", []);
    };

	// 上拉加载
	const onLoad = () => {
		state.page++;
		getdata();
    }

	// 获取数据
	const getdata = () => {
		if(state.page == 1){
			proxy.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: "加载中..."
			});
		}
        sensorTrack({
            eventName: 'HuaTuEducation_m_htxd_search',
            params: {
                search_keyword: state.keyword,
                $url: window.location.href
            }
        }).then(rData => {
            proxy.$http.get("/api/course/list",{
                params: {
                    shop_id: state.shop_id,
                    page: state.page,
                    keyword: state.keyword
                }
            })
            .then(res => {
                const body = res.data.data;
                proxy.$toast.clear();

                if(res.data.code == '200'){
                    if(state.page == 1){
                        // 数据总数
                        state.total = body.total;
                    }

                    //停止加载loading
                    state.loading = false;
                    
                    // 首次加载
                    state.is_search = true;

                    if(state.courseList == '' && body.data == '' && state.keyword !== ''){
                        state.show = true
                    } else {
                        state.show = false
                    }

                    if (body.data == null || body.data.length === 0) {
                        // 加载结束
                        state.finished = true;
                        return;
                    }

                    // 将新数据与老数据进行合并
                    state.courseList = state.courseList.concat(body.data);

                    //如果列表数据条数>=总条数，不再触发滚动加载
                    if (state.courseList.length >= state.total) {
                        state.finished = true;
                    }

                    highlights()
                    
                } else {
                    console.log(res.data.code)
                }
            })
            .catch(error => console.log(error))
        });
	}
    // 搜索关键字高亮
    const highlights  = () => {
        const search = state.keyword
        state.courseList = state.courseList.map(item => {

            let replaceReg = new RegExp(search, 'g')// 匹配关键字正则
            let replaceString = '<span class="highlights-text">' + search + '</span>' // 高亮替换v-html值
            item.title = item.title.replace(replaceReg, replaceString) // 开始替换

            if(item.type == 0 || item.type == 1 || item.type == 7){
                let span = '<span style="padding-left:42px"></span>'
                item.title01 = span + item.title;
            }else{
                item.title01 = item.title;
            }

            return item
        })
    }

    // 获取热门搜索词
    const getHotwords = () => {
        proxy.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载中..."
        });

        proxy.$http.get("/api/course/hotwords")
            .then(res => {
                const body = res.data.data;
                proxy.$toast.clear();

                if(res.data.code == '200'){
                    state.hotwordsList = body;
                } else {
                    console.log(res.data.code)
                }
            })
            .catch(error => console.log(error))
    }

    return {
        state,
        filter,
        getdata,
        onLoad,
        onSearch,
        onCancel,
        deleteHistory,
        selectTag,
        highlights,
        getHotwords,
        hotSearch
    };
  }
};
</script>

<style scoped lang="scss">
.search-page{
    background-color: #ffffff;
    .div{
        height: 50px;
    }
    .history-list{
        padding: 15px;
        .history-search{
            font-size: 13px;
            line-height: 18px;
            color: #666666;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon-delete{
                width: 16px;
                height: 16px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/home/icon_delete.png), center no-repeat;
                background-size: 100% 100%;
            }
        }
        .histort-item{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .item{
                background: #F5F6F8;
                border-radius: 2px;
                line-height: 28px;
                font-size: 14px;
                color: #333333;
                text-align: center;
                padding: 0 12px;
                margin: 12px 12px 0 0;
            }
        }
    }
    /deep/.van-search__content{
        border-radius: 16px;
    }
}
</style>
