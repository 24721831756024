<template>
    <div class="page-face-lesson">
        <vanTitle title="课程目录"></vanTitle>
        <!-- 课程tab -->
        <div class="detail-tabs">
            <van-tabs :active="state.active" color="#FC6C21" line-width="14px" title-active-color="#FC6C21" title-inactive-color="#999999" swipeable @click="bindclick">
                <van-tab title="课节">
                    <lessonList :platform="state.platform" :goodsid="state.goodsid" :course_id="state.course_id" ref="lessonList" load="true"></lessonList>
                </van-tab>
                <van-tab title="资料">
                    <ziLiao :platform="state.platform" :goodsid="state.goodsid" ref="ziLiao"></ziLiao>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance,watchEffect,ref } from "vue";
import lessonList from "../../components/detail/lesson.vue";
import ziLiao from "../../components/detail/ziliao.vue";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "page-face-lesson",
  components: {
      lessonList,
        ziLiao,
    },
  setup() {
    const { proxy } = getCurrentInstance();
    const bindlessonList = ref();
	const $route = useRoute();
	const $router = useRouter();
    const lessonList = ref();
    const ziLiao = ref();
    const state = reactive({
            active: 0,//tabs选中
            platform:'',//课程归属
            goodsid:'',//商品ID
            course_id:'',//课程ID
        });

    onMounted(() => {
        if($route.query.id){
            state.course_id = $route.query.id
        }
        if($route.query.goodsid){
            state.goodsid = $route.query.goodsid
        }
        if($route.query.platform){
            state.platform = $route.query.platform
        }
    })

    // 切换tabs标签前触发
    const bindclick = (index) => {
        state.active = index;
        console.log(index);
        if(index === 0){
            lessonList.value.getLesson(1);
        } else if(index === 1){
            ziLiao.value.getData(1);
        }
        return true;
    }

    return {
		state,
        lessonList,
        ziLiao,
        bindlessonList,
        bindclick
    };
  }
};
</script>